<template>
    <section v-if="!loading && modules.length > 0">
        <div class="fw600 f16 mb-5">
            Course Outline
        </div>

        <div v-for="(item) in modules" :key="item.id" class="mb-5 ml-2">
            <section class="description_div fw500 f13">
                {{item.title ? item.title : 'Untitled' }} 
            </section>
            <!-- <section v-if="item.description" class="description_div f13 mt-1 secondary-2--text" v-html="item.description"></section> -->
            <section v-if="!['anytime', 'lock'].includes(item.valid_until)" class="f12 roboto f11 fw500">
                <span class="secondary-2--text"> SCHEDULE : </span>{{ $dateFormat.mmDDyy(item.valid_until) }}
            </section>
        </div>

    </section>
</template>

<script>

export default {
    props: ['modules', 'loading'],
}
</script>